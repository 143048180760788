const solutionsData = [
    {
        solutionTitle: "Name Searches",
        solutionTitleImg: "./img/name-searched-accordion-logo.svg",
        metaDescription: "",
        services: [
            {
                serviceTitle: "Child Support Judgment Search",
                metaDescription: "Providing certified NJ child support judgment searches services for any time period up to 20 years. As little as 24-hour turnaround.",
                availableFor: ["New Jersey"],
                certified: "Yes",
                url: "/solutions/name-searches/child-support-judgment",
                showDivider: true,
                image: "/img/solutions/child_support.jpg",
                description: () => {
                    return (
                        <>
                            <p className="solutions-details-page-p">
                                <span>
                                    Rely on our certified New Jersey child support judgment
                                    search.&nbsp;
                                </span>
                                NJ State legislation requires attorneys in civil actions to
                                ascertain whether their clients are child support judgment
                                debtors before releasing a monetary award or settlement. To
                                expedite this process and to facilitate compliance with the
                                law, we offer Certified Child Support Judgment Searches.
                            </p>
                            <p className="solutions-details-page-p">
                                Within 24-48 hours, we will issue a certified child support
                                judgment search confirming whether a child support judgment
                                exists against the name(s) requested. We have been offering
                                searches of child support judgments since they were first
                                filed by the Probation Division of the Superior Court.
                            </p>
                        </>
                    );
                }
            },
            {
                serviceTitle: "Judgment Lien Search",
                metaDescription: "Providing certified judgment lien search services for any time period up to 20 years. As little as 24-hour turnaround.",
                availableFor: ["New Jersey"],
                certified: "Yes",
                url: "/solutions/name-searches/judgment-lien-search",
                showDivider: true,
                image: "/img/solutions/judgment_lien.jpg",
                description: () => {
                    return (
                        <>
                            <p className="solutions-details-page-p">
                                Charles Jones' certified searches are an indispensable
                                resource for real estate, lending, and legal professionals
                                who need a fast, reliable, and accurate NJ judgment search.
                                Our comprehensive New Jersey judgment lien search identifies
                                real estate liens, judgments, bankruptcies, and child support
                                records. With over 100 years of expertise, Charles Jones
                                delivers trusted data, including screening, to help you
                                conduct secure and compliant transactions.
                            </p>
                        </>
                    )
                }
            },
            {
                serviceTitle: "Patriot Name Search",
                metaDescription: "",
                availableFor: ["Nationwide"],
                certified: "Yes",
                url: "/solutions/name-searches/patriot-name-search",
                showDivider: false,
                image: "/img/solutions/patriot.jpg",
                description: () => {
                    return (
                        <>
                            <p className="solutions-details-page-p">
                                <span>
                                    The Charles Jones Patriot Name Search is a Certified
                                    Search&nbsp;
                                </span>
                                of lists published by the Office of Foreign Assets Control
                                of the U.S. Department of Treasury ("OFAC"), consisting of
                                the "Specially Designated Nationals and Blocked Persons",
                                the "Foreign Sanctions Evaders", and the comprehensive
                                “Consolidated List”. Charles Jones offers this search as an
                                option when ordering a Statewide Judgment Lien Search or
                                through a separate Service Selection on our online order
                                entry and retrieval system.
                            </p>
                            <p className="solutions-details-page-p">
                                Effective September 24, 2001, Executive Order 13224 was
                                issued (as amended by Order 13268, and 13608) and requires
                                anyone conducting a financial transaction to determine if
                                the person(s) with whom they are doing business are listed
                                on the Blocked Persons list. The USA PATRIOT Act of 2001
                                requires similar checks and procedures. Later Executive
                                Orders and sanctions have been imposed that require a check
                                of other names that appear on OFAC's "The Consolidated List"
                                before transacting business.
                            </p>
                            <p className="solutions-details-page-p">
                                The search also provides business general information on
                                what to do if an individual name or entity appears on your
                                results.
                            </p>
                        </>
                    )
                }
            },
        ],
    },
    {
        solutionTitle: "Tax Searches",
        solutionTitleImg: "./img/tax-search-accordion-logo.svg",
        metaDescription: "Charles Jones delivers certified New Jersey tax assessment records, NJ municipal searches. We offer property lien search products and PA tax certifications, supporting secure real estate transactions in NJ and PA.",
        services: [
            {
                serviceTitle: "New Jersey Tax and Assessment Searches",
                metaDescription: "Charles Jones provides New Jersey Tax Assessment searches, including lienable public utility info. NJ tax and assessment search services are fast, concise, comprehensive, and certified.",
                availableFor: ["New Jersey"],
                certified: "Yes",
                url: "/solutions/tax-searches/nj-tax-and-assessment-searches",
                showDivider: true,
                image: "/img/solutions/tax_assessment_search.jpg",
                description: () => {
                    return (
                        <>
                            <p className="solutions-details-page-p">
                                Beginning in 1985, we began providing the first privately
                                produced New Jersey tax search, detailing tax billing,
                                payment status, utility information, and all lienable
                                charges. The Charles Jones tax assessment search allows
                                easy property identification through a statewide database,
                                expediting your search process.
                            </p>
                            <p className="solutions-details-page-p">
                                We provide fast, concise, and comprehensive NJ tax and
                                assessment search results in your choice of formats,
                                ensuring high-quality certified searches. With automated
                                systems and experienced professionals, we deliver accurate,
                                reliable results for all property and tax-related inquiries.
                            </p>
                        </>
                    )
                }
            },
            {
                serviceTitle: "Pennsylvania Tax Certifications",
                metaDescription: "PA tax certifications since 1996, contain lienable info, including public utility and lien letter content. Cover 3,600+ taxing authorities.",
                availableFor: ["Pennsylvania"],
                certified: "Yes",
                url: "/solutions/tax-searches/pa-tax-certifications",
                showDivider: false,
                image: "/img/solutions/tax_certifications.jpg",
                description: () => {
                    return (
                        <>
                            <p className="solutions-details-page-p">
                                We have been providing Tax Certifications in Pennsylvania
                                since 1996 that contain all lienable information, including
                                public utility and lien letter content. Our experience
                                with PA Tax Certifications helps you take the guesswork
                                out of your search, ensuring certified results and consistent
                                formatting across all searches.
                            </p>
                            <p className="solutions-details-page-p">
                                Your experience with our PA Tax Search product begins
                                with access to an expansive property database. This
                                will allow you to correctly identify properties you
                                want to order. You have the option of including a PA
                                Utility Search as well. Our statewide coverage includes
                                more than 3,600 taxing authorities in Pennsylvania
                                and allows you to obtain a PA Tax Certification and
                                Utility Search online.
                            </p>
                            <p className="solutions-details-page-p">
                                We make search easy for you:
                            </p>
                            <span className="solutions-details-page-p">
                                <ul>
                                    <li>
                                        Write just one check for your tax and utility needs.
                                    </li>
                                    <li>
                                        Concise, consistent formatting means you can
                                        spend less time reviewing the search since
                                        the information is provided the same way each
                                        time and in each municipality.
                                    </li>
                                </ul>
                            </span>
                        </>
                    )
                }
            },
        ],
    },

    {
        solutionTitle: "Flood and Tideland Services",
        solutionTitleImg: "./img/flood.svg",
        metaDescription: "",
        services: [
            {
                serviceTitle: "Flood Search Determination",
                metaDescription: "",
                availableFor: ["Nationwide"],
                certified: "No",
                url: "/solutions/flood-and-tideland/flood-search-determination",
                showDivider: true,
                image: "/img/solutions/flood_search.jpg",
                description: () => {
                    return (
                        <>
                            <p className="solutions-details-page-p">
                                A flood search is a determination of whether all or a portion
                                of a designated property is located in a special or moderate
                                flood hazard area (i.e., a 100- or 500-year flood zone) based
                                upon the Flood Insurance Rate Maps issued by the FEMA for
                                the sole purpose of complying with the Flood Disaster
                                Protection Act. (Standard flood hazard searches are not
                                intended as an opinion as to the likelihood of flooding
                                for a particular property).
                            </p>
                            <p className="solutions-details-page-p">
                                A Charles Jones flood search includes our detailed, descriptive
                                maps and all the information required for compliance with
                                federal regulations. We also perform flood searches for partial
                                flooding areas to determine if a dwelling or other structure is
                                located in a flood zone. This comprehensive search can help
                                provide answers when the entire property is not in a flood zone,
                                since insurance is only required for dwellings and insurable
                                improvements.
                            </p>
                            <p className="solutions-details-page-p">
                                The company is a member of the National Flood Determination
                                Association (NFDA), has over 30 years of experience performing
                                these searches and currently maintains one of the region's
                                largest inventories of map resources and data files.
                            </p>
                        </>
                    )
                }
            },
            {
                serviceTitle: "Life-of-Loan Flood Monitoring",
                metaDescription: "",
                availableFor: ["Nationwide"],
                certified: "No",
                url: "/solutions/flood-and-tideland/lol-flood-monitoring",
                showDivider: true,
                image: "/img/solutions/lol_flood.jpg",
                description: () => {
                    return (
                        <>
                            <p className="solutions-details-page-p">
                                The National Flood Insurance Reform Act of 1994 requires
                                that lenders must monitor loans to ensure that flood
                                insurance is continued for the life of the loan and that
                                changes in Flood Insurance Rate Maps be reflected in the
                                requirement for insurance and the amount of premiums paid.
                                To comply with this requirement, Charles Jones is pleased to
                                provide an automated life-of-loan tracking service to
                                identify properties affected by periodic changes in flood
                                maps.
                            </p>
                        </>
                    )
                }
            },
            {
                serviceTitle: "Tideland Claims Search",
                metaDescription: "Learn about Flood and Tideland services available including Wetland Determination and Life-of-Loan Flood Monitoring. Get complete details about how tideland search services are completed quickly.",
                availableFor: ["New Jersey"],
                certified: "Yes",
                url: "/solutions/flood-and-tideland/tideland-claims-search",
                showDivider: true,
                image: "/img/solutions/tideland_claims.jpg",
                description: () => {
                    return (
                        <>
                            <p className="solutions-details-page-p">
                                A certified search of riparian rights and claims established
                                by the state of New Jersey to portions of property lying in
                                areas presently or formerly flowed by tidal waters. Searches
                                are made using maps and overlays adopted by the Tidelands
                                Resource Council, tax maps, U.S. Geological Survey maps,
                                computerized index files, and other source materials.
                                Claimed areas are identified by the type of claim and the
                                approximate area claimed.
                            </p>
                            <p className="solutions-details-page-p">
                                The product was developed in response to the O'Neill
                                decision by the New Jersey Supreme Court, 50 NJ 307
                                (1967), and ensuing legislation, NJSA 13:1B et seq., the
                                State of New Jersey has promulgated over 1600 maps which
                                delineate areas to which the State claims ownership because
                                the lands are presently or were formerly flowed by the tides
                                or tidal streams. The validity of these far-reaching and
                                frequently unsuspected claims of ownership was supported by
                                the U.S. Supreme Court in Phillips Petroleum vs.
                                Mississippi, U.S. Supreme Court, Feb. 23, 1988, Case No.
                                86-870. Many attorneys, title companies and title searchers
                                do not have the time or resources to make an accurate study
                                of these claims maps in relation to their property. But the
                                cost of an improper determination from the maps can be very
                                substantial. With a long history of services to the land
                                title industry, Charles Jones recognizes the need for fast,
                                reliable tideland searches and has developed the resources
                                necessary to provide a comprehensive service.
                            </p>
                        </>
                    )
                }
            },
            {
                serviceTitle: "Tideland Grant Search",
                metaDescription: "",
                availableFor: ["New Jersey"],
                certified: "Yes",
                url: "/solutions/flood-and-tideland/tideland-grant-search",
                showDivider: true,
                image: "/img/solutions/tideland_grant.jpg",
                description: () => {
                    return (
                        <>
                            <p className="solutions-details-page-p">
                                If a property is found to have a Tideland Claim, the Charles
                                Jones Tideland Grant Search shows prior grants and leases of
                                any claimed area. We have the maps, data and expertise to
                                perform the work in-house. Expect precision, accuracy and
                                fast turnaround!
                            </p>
                        </>
                    )
                }
            },
            {
                serviceTitle: "Wetland Determination",
                metaDescription: "",
                availableFor: ["New Jersey"],
                certified: "No",
                url: "/solutions/flood-and-tideland/wetland-determination",
                showDivider: false,
                image: "/img/solutions/wetland.jpg",
                description: () => {
                    return (
                        <>
                            <p className="solutions-details-page-p">
                                An opinion regarding the possible existence of wetlands on a
                                designated property. The process involves research of
                                topographic maps, aerial photography, soil survey maps,
                                National Wetland Inventory maps, drainage basin and water
                                supply overlays, and delineations of the specially protected
                                New Jersey Pinelands.
                            </p>
                            <p className="solutions-details-page-p">
                                These maps permit us to predict the likelihood of wetlands
                                using the standard "three parameter" definition of wetlands
                                based upon: soils, vegetation and hydrography. Since each
                                variable must work together with other factors to establish
                                a wetland, only an on-site inspection can delineate the
                                extent of wetlands with certainty. However, a wetlands
                                opinion provides a low cost, timely report on the likelihood
                                of wetlands and can save significant time and money before a
                                comprehensive investigation is made.
                            </p>
                        </>
                    )
                }
            },
        ],
    },

    {
        solutionTitle: "County Search Services",
        solutionTitleImg: "./img/county-search.svg",
        metaDescription: "",
        services: [
            {
                serviceTitle: "County Search Services",
                metaDescription: "Providing county search services and NJ property records search available for all 21 New Jersey counties. DTS Title Plants expedite searches with clear and concise reporting data.",
                availableFor: ["New Jersey"],
                certified: "No",
                url: "/solutions/county-search/county-search",
                showDivider: false,
                image: "/img/solutions/county_search.jpg",
                description: () => {
                    return (
                        <>
                            <p className="solutions-details-page-p">
                                We offer County Search services for all 21 counties in New
                                Jersey. Plus, with the development of DTS Title Plants in
                                numerous counties throughout New Jersey, our County Search
                                Services gives you extra power. We use experienced
                                searchers, plus the power of the Title Plant to expedite
                                completion of your County Searches. Because many of our
                                searches can be completed using the plants, we are not
                                limited by the hours of county record rooms. Seasoned,
                                professional searchers execute your orders, and you receive
                                clear, detailed, type-written title reports quickly.
                            </p>
                            <p className="solutions-details-page-p">
                                Our searches include a search of the index of county land
                                records from the date that the current title holder(s)
                                acquired the property through the present board date.
                                Primary Types: Present Owner, Full Searches, Foreclosure and
                                Commercial. Projects of any size can be completed in a
                                timely, predictable and professional manner with just one
                                place to call with your questions.
                            </p>
                            <p className="solutions-details-page-p">
                                For an updated list of our title plant county availability,
                                &nbsp;
                                <a href="/dts-title-plant" rel="noreferrer">
                                    click here
                                </a>
                                .
                            </p>
                        </>
                    )
                }
            },
        ],
    },

    {
        solutionTitle: "Corporate and UCC Services",
        solutionTitleImg: "./img/corporate_fare.svg",
        metaDescription: "Conduct an NJ UCC search or access a range of other corporate UCC services? Charles Jones offers accurate & reliable solutions in NJ and PA with quick turnaround times.",
        services: [
            {
                serviceTitle: "Status Report",
                metaDescription: "",
                availableFor: ["New Jersey", "Pennsylvania"],
                certified: "No",
                url: "/solutions/corporate-and-ucc/status-report",
                showDivider: true,
                image: "/img/solutions/corp_status_report.jpg",
                description: () => {
                    return (
                        <>
                            <p className="solutions-details-page-p">
                                You will receive a report that supplies the information you
                                need about a corporation such as: date of formation, name of
                                the registered agent, registered office of the entity and
                                the status (active, dissolved, revoked) of the entity, and
                                principal office address if any. Typical turnaround time:
                                Within 24 hours. Available online in New Jersey and
                                Pennsylvania.
                            </p>
                        </>
                    )
                }
            },
            {
                serviceTitle: "Franchise Tax/Corporate Tax Lien Report",
                metaDescription: "",
                availableFor: ["New Jersey", "Pennsylvania"],
                certified: "No",
                url: "/solutions/corporate-and-ucc/franchise-and-corporate-tax-lien-report",
                showDivider: true,
                image: "/img/solutions/franchise_tax.jpg",
                description: () => {
                    return (
                        <>
                            <p className="solutions-details-page-p">
                                All domestic or foreign corporations transacting business in
                                New Jersey are subject to the payment of franchise taxes.
                                Unpaid franchise taxes constitute a lien on all of the
                                corporation's property for a period of ten years, which is
                                computed from the first day of the year in which the tax is
                                due and payable. However, the lien is imposed on the first
                                day of the year following the year in which the tax is due.
                            </p>
                            <p className="solutions-details-page-p">
                                Typical State turnaround time: Once you enter your order, we
                                send it directly to the State Tax Department for completion.
                                Typical state turnaround 6-8 weeks.
                            </p>
                            <p className="solutions-details-page-p">
                                Charles Jones also provides a Pennsylvania report detailing
                                Tax Lien Status for all entities doing business in the
                                Commonwealth. That report outlines Tax Status with PA's
                                Division of Revenue, including notification of any liens
                                filed or franchise taxes due.
                            </p>
                        </>
                    )
                }
            },
            {
                serviceTitle: "Good Standing Certificate",
                metaDescription: "Get good standing certificate reports, NJ judgment reports, tax lien reports, legal existence, and search features to expedite business transactions.",
                availableFor: ["New Jersey"],
                certified: "No",
                url: "/solutions/corporate-and-ucc/good-standing",
                showDivider: true,
                image: "/img/solutions/good_standing_certificate.jpg",
                description: () => {
                    return (
                        <>
                            <p className="solutions-details-page-p">
                                The Charles Jones Good Standing Certificate is provided in
                                either Long or Short form, and provides conclusive evidence
                                from the State that the company is active and all reports
                                and taxes are current within two years.
                            </p>
                        </>
                    )
                }
            },
            {
                serviceTitle: "Legal Existence (Good Standing) Certificate",
                metaDescription: "",
                availableFor: ["New Jersey", "Pennsylvania"],
                certified: "No",
                url: "/solutions/corporate-and-ucc/legal-existence",
                showDivider: true,
                image: "/img/solutions/legal_existence.jpg",
                description: () => {
                    return (
                        <>
                            <p className="solutions-details-page-p">
                                This certificate provides conclusive status information for
                                any entity registered with the state, or commonwealth.
                            </p>
                        </>
                    )
                }
            },
            {
                serviceTitle: "Corporate Copies",
                metaDescription: "",
                availableFor: ["New Jersey", "Pennsylvania"],
                certified: "No",
                url: "/solutions/corporate-and-ucc/corporate-copies",
                showDivider: true,
                image: "/img/solutions/corp_copies.jpg",
                description: () => {
                    return (
                        <>
                            <p className="solutions-details-page-p">
                                State issued copies of any document filed with Secretary of
                                State in New Jersey and the Secretary of the Commonwealth in
                                Pennsylvania.
                            </p>
                        </>
                    )
                }
            },
            {
                serviceTitle: "UCC Plus",
                metaDescription: "NJ UCC Plus corporate search solutions. Fixed fee - no hidden fees, no guesswork - with all copies included.",
                availableFor: ["New Jersey"],
                certified: "No",
                url: "/solutions/corporate-and-ucc/ucc-plus",
                showDivider: true,
                image: "/img/solutions/ucc_plus.jpg",
                description: () => {
                    return (
                        <>
                            <p className="solutions-details-page-p">
                                Charles Jones UCC Plus Search features a unique Dual Index
                                Search, which examines both the NJ State UCC records as well
                                as a proprietary database to complete your search faster,
                                with refined results. Most results and all pertinent copies
                                are returned in 4 hours or less, helping to expedite your
                                business transaction. UCC Plus also offers a fixed fee - no
                                hidden fees, no guesswork - with all copies included.
                            </p>
                        </>
                    )
                }
            },
            {
                serviceTitle: "UCC State Certified",
                metaDescription: "",
                availableFor: ["New Jersey"],
                certified: "Yes",
                url: "/solutions/corporate-and-ucc/ucc-state-certified",
                showDivider: true,
                image: "/img/solutions/ucc_state_certified.jpg",
                description: () => {
                    return (
                        <>
                            <p className="solutions-details-page-p">
                                A certified report issued by the New Jersey Division of
                                Revenue (the filing office for the UCC system in New Jersey)
                                against the name you request. Typically returned within
                                24-48 hours.
                            </p>
                        </>
                    )
                }
            },
            {
                serviceTitle: "UCC State Report",
                metaDescription: "",
                availableFor: ["Pennsylvania"],
                certified: "No",
                url: "/solutions/corporate-and-ucc/ucc-state-report",
                showDivider: false,
                image: "/img/solutions/ucc_state_report.jpg",
                description: () => {
                    return (
                        <>
                            <p className="solutions-details-page-p">
                                Uniform Commercial Code report issued against the name(s)
                                you request, detailing all findings filed with the
                                Commonwealth.
                            </p>
                        </>
                    )
                }
            },
        ],
    },

    {
        solutionTitle: "Nationwide Services",
        solutionTitleImg: "./img/flag.svg",
        metaDescription: "Proving commercial due diligence services, corporate filing, and life-of-loan flood monitoring.",
        services: [
            {
                serviceTitle: "Due Diligence Services",
                metaDescription: "Charles Jones provides expert commercial due diligence services and nationwide court searches. Our clients rely on our proven expertise in due diligence services for legal, financial, and real estate transactions.",
                availableFor: ["Nationwide"],
                certified: "No",
                url: "/solutions/nationwide-services/due-diligence",
                showDivider: true,
                image: "/img/solutions/due_dilligence.jpg",
                description: () => {
                    return (
                        <>
                            <p className="solutions-details-page-p">
                                We offer commercial due diligence products covering a vast
                                range of courts and record types both locally, and nationwide.
                                Our team of professionals has more than 100 years of experience
                                working with legal, lending, title and corporate professionals
                                seeking information provided within our many search and retrieval
                                products.
                            </p>
                            <p className="solutions-details-page-p">
                                Need a copy of a document? Our Business Entity Document Retrieval
                                Services return the essential filing copies you may need such
                                as Certificates of Incorporation or any documents required for
                                companies conducting business. We also provide Uniform Commercial
                                Code (UCC) Search and Retrieval Services as well as Court Record
                                Search and Retrieval offerings covering record types and jurisdictions
                                across the country. Our time-tested search practices return meaningful
                                results, designed to provide the information and documents vital
                                to any commercial transaction.
                            </p>
                            <p className="solutions-details-page-p">
                                Take advantage of our online order entry and retrieval - with
                                multiple delivery options.
                            </p>
                            <p className="solutions-details-page-p">
                                For a snapshot of our commercial due diligence offerings - detailed
                                information on the products and how to order&nbsp;
                                <a
                                    href="/SignOn/information/SignOnNcsGuide.pdf"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Click Here
                                </a>
                                .
                            </p>
                        </>
                    )
                }
            },
            {
                serviceTitle: "Corporate Filing & Registered Agent Services",
                metaDescription: "",
                availableFor: ["Nationwide"],
                certified: "No",
                url: "/solutions/nationwide-services/corp-filing-registered-agent",
                showDivider: true,
                image: "/img/solutions/corp_filing.jpg",
                description: () => {
                    return (
                        <>
                            <p className="solutions-details-page-p">
                                Charles Jones is your source for filing corporate documents
                                locally and nationwide. Our services include filing
                                submissions and retrievals for all types, also including
                                amendments, mergers, dissolutions, and withdrawals. If you
                                need a commercial registered agent, we can also provide this
                                service nationwide or internationally through our corporate
                                affiliation with NRAI.
                            </p>
                        </>
                    )
                }
            },
            {
                serviceTitle: "Flood Search",
                metaDescription: "",
                availableFor: ["Nationwide"],
                certified: "No",
                url: "/solutions/nationwide-services/flood-search",
                showDivider: true,
                image: "/img/solutions/flood_search.jpg",
                description: () => {
                    return (
                        <>
                            <p className="solutions-details-page-p">
                                A flood search is a determination of whether all or a
                                portion of a designated property is located in a special or
                                moderate flood hazard area (i.e., a 100 or 500 year flood
                                zone) based upon the Flood Insurance Rate Maps issued by the
                                FEMA for the sole purpose of complying with the Flood
                                Disaster Protection Act. (Standard flood hazard searches and
                                certifications are not intended as an opinion as to the
                                likelihood of flooding for a particular property).
                            </p>
                            <p className="solutions-details-page-p">
                                A Charles Jones flood search certification contains (and has
                                for many years) all of the information required for
                                compliance with federal regulations. Flood Searches are also
                                performed for partial flooding areas to determine if a
                                dwelling or other structure is located in a flood zone. This
                                Dwelling Search can help provide answers when the entire
                                property is not in a flood zone, since insurance is only
                                required for dwellings and insurable improvements.
                            </p>
                            <p className="solutions-details-page-p">
                                The company is a member of the National Flood Determination
                                Association (NFDA), has over 30 years of experience
                                performing these searches and currently maintains one of the
                                region's largest inventories of map resources and data
                                files.
                            </p>
                        </>
                    )
                }
            },
            {
                serviceTitle: "Life-of-Loan Flood Monitoring",
                metaDescription: "",
                availableFor: ["Nationwide"],
                certified: "No",
                url: "/solutions/nationwide-services/lol-flood-monitoring",
                showDivider: true,
                image: "/img/solutions/lol_flood.jpg",
                description: () => {
                    return (
                        <>
                            <p className="solutions-details-page-p">
                                The National Flood Insurance Reform Act of 1994 requires
                                that lenders must monitor loans to ensure that flood
                                insurance is continued for the life of the loan and that
                                changes in Flood Insurance Rate Maps be reflected in the
                                requirement for insurance and the amount of premiums paid.
                                To comply with this requirement, Charles Jones is pleased to
                                provide an automated life-of-loan tracking service to
                                identify properties affected by periodic changes in flood
                                maps.
                            </p>
                        </>
                    )
                }
            },
            {
                serviceTitle: "Patriot Name Search",
                metaDescription: "Use a patriot name search to ensure someone is not on a Blocked Persons List, Foreign Sanctions Evaders list, or other OFAC lists.",
                availableFor: ["Nationwide"],
                certified: "Yes",
                url: "/solutions/nationwide-services/patriot-name-search",
                showDivider: false,
                image: "/img/solutions/patriot.jpg",
                description: () => {
                    return (
                        <>
                            <p className="solutions-details-page-p">
                                <span>
                                    The Charles Jones Patriot Name Search is a Certified
                                    Search&nbsp;
                                </span>
                                of lists published by the Office of Foreign Assets Control
                                of the U.S. Department of Treasury ("OFAC"), consisting of
                                the "Specially Designated Nationals and Blocked Persons",
                                the "Foreign Sanctions Evaders", and the comprehensive
                                “Consolidated List”. Charles Jones offers this search as an
                                option when ordering a Statewide Judgment Lien Search or
                                through a separate Service Selection on our online order
                                entry and retrieval system.
                            </p>
                            <p className="solutions-details-page-p">
                                Effective September 24, 2001, Executive Order 13224 was
                                issued (as amended by Order 13268, and 13608) and requires
                                anyone conducting a financial transaction to determine if
                                the person(s) with whom they are doing business are listed
                                on the Blocked Persons list. The USA PATRIOT Act of 2001
                                requires similar checks and procedures. Later Executive
                                Orders and sanctions have been imposed that require a check
                                of other names that appear on OFAC's "The Consolidated List"
                                before transacting business.
                            </p>
                            <p className="solutions-details-page-p">
                                The search also provides business general information on
                                what to do if an individual name or entity appears on your
                                results.
                            </p>
                        </>
                    )
                }
            },
        ],
    },
];

export default solutionsData;
